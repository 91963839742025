<template>
  <div>
    <dialog-edit :value="value" @input="$emit('close', data)" @keydown.esc="$emit('input')" maxWidth="1200px">
      <template v-slot:title>
        <div class="d-flex nowrap2 justify-content-end">
          <span>{{ data.title }}</span>
          <v-spacer />
          <portal-target v-show="data.phone > 1" name="v-caller"></portal-target>
        </div>
      </template>

      <v-row>
        <v-col cols="12" sm="6" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>
              <div class="px-0 py-0">Объект</div>
            </template>
            <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: !true }" @click="onClick($event)" />
          </material-card>
        </v-col>
        <v-col cols="12" sm="6" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>Собственник</template>
            <a-form-model v-model="data" :model="modelRight" :errors="errors" :config="config" @validate="validate($event)" class="px-4" />
          </material-card>
        </v-col>
        <v-col cols="12" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>Дополнительные данные</template>
            <v-btn v-if="data.vendor_id && !isInfo" @click="getInfo" :loading="isInfoProcess">
              Запросить данные
              <template v-slot:loader>
                <span>Загрузка...</span>
              </template>
            </v-btn>
            <a-form-model v-if="m && isInfo" v-model="data" :model="calcModel('detail1')" :errors="errors" :config="config" @validate="validate($event)" class="px-4" />
          </material-card>
        </v-col>
        <v-col cols="12" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>
              <div class="px-3 py-0 d-flex flex-wrap" style="justify-content: space-between">
                <div class="align-self-center">Фото без водяных знаков</div>
                <div>
                  <v-btn v-if="data.images && data.images.length" :href="$root.config.imageUrl + 'all/offer/' + id" text>
                    <v-icon class="mr-2">fas fa-save</v-icon>
                    скачать архив
                  </v-btn>
                </div>
              </div>
            </template>
            <v-btn v-if="data.vendor_id && !isPhoto" @click="getPhotoUnmark" :loading="isInfoProcess">
              Получить фото
              <template v-slot:loader>
                <span>Загрузка...</span>
              </template>
            </v-btn>

            <a-view-images v-if="isPhoto" :value="data.images" />
          </material-card>
        </v-col>
      </v-row>
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>

      <a-loader v-if="loading" />
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn v-if="getAccess('object.create') && id" @click="onStartWork()" title="В избранное">
          <v-icon>far fa-save</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">В избранное</btn-title>
        </v-btn>
        <v-btn @click="$emit('close', data)" title="Закрыть">
          <v-icon>far fa-window-close</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">Закрыть</btn-title>
        </v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
//import parser from "./../parser";

import { autoHeightBlock, getAccess, popupMenu, genModel, getForm, submitForm } from "../../components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, genModel, getForm, submitForm],
  components: {},
  props: {
    value: Boolean,
    autoCall: { type: Boolean, default: false },
    offer: { type: Object, default: () => {} },
  },
  data() {
    return {
      id: 0,
      loading: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.offer,
      showEditDialog: false,
      model: [],
      blockHeight: 256,
      isInfoProcess: false,
    };
  },
  created() {
    this.$root.title = this.m.title || this.$root.title;
    this.model = this.calcModelConfig(this.m?.config.default || {});
  },
  mounted() {},

  computed: {
    isInfo() {
      let res = false;
      if (this.data?.description || (this.data?.ads_info?.data && this.data?.ads_info?.data?.resp.status !== 429)) res = true;
      return res;
    },
    isPhoto() {
      if (this.data?.images && this.data.images.length) return true;
      return false;
    },
    phoneStatus: {
      get() {
        return this.$store.getters["config/getPhoneStatus"];
      },
    },
    url() {
      return this.m.url;
    },
    api() {
      return this.m.url;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model11: {
      get() {
        let config = JSON.parse(JSON.stringify(this.m?.config.default || {}));
        let model = this.calcModelConfig(config);
        return model;
      },
    },
    config() {
      return { dense: true, readonly: false, hideDetails: "auto" };
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
      }
    },
    value(v) {
      if (v) {
        this.reset();
        this.fillForm();
        this.fetchData();
        this.$root.$emit("caller-show");
      } else {
        this.data = {};
        this.$root.$emit("caller-hide");
      }
    },
  },
  methods: {
    onCall(item) {
      if (this.phoneStatus.status !== true) return;
      if (isNaN(item.phone)) return;
      if (item.phone) this.$root.$emit("global-call", item.phone);
    },
    reset() {
      this.loading = false;
      this.isInfoProcess = false;
      this.data = {};
    },
    itemShowClose() {
      if (this.idShow) this.$emit("close");
      else this.$router.push({ name: this.$route.path.split("/")[1] });
    },
    async getId() {
      this.id = null;
      try {
        if (this.offer?.id) {
          this.id = this.offer.id;
        } else {
          let filters = { vendor: this.offer.vendor, vendor_id: this.offer.vendor_id };
          let params = {
            sort: JSON.stringify({ key: "id", order: "asc" }),
            filters,
            pager: this.pager,
          };

          let resp = await this.$axios.get(this.url, { params });
          let data;
          if ((data = resp.data.data)) {
            this.id = data[0].id;
            this.data.ads_info = JSON.parse(JSON.stringify(data[0].ads_info));
            this.data.images = JSON.parse(JSON.stringify(data[0].images));
            ///this.$set(this.data, "ads_info", data[0].ads_info);
          }
        }
      } catch (error) {}
    },
    async fetchData(id) {
      this.loading = true;
      let data = {};
      if (this.offer.id) {
        let resp = await this.$axios.get(this.url + "/" + this.offer.id);
        let d;
        if ((d = resp.data.data)) {
          data = d; ///this.$set(this.data, "ads_info", data[0].ads_info);
        }
      } else {
        data = this.offer;
      }
      for (const name in data) {
        if (name == "data" && this.data?.data && data[name]) {
          for (const nameData in data.data) {
            this.$set(this.data.data, nameData, data.data[nameData]);
            this.$set(this.errors, nameData, null);
          }
        } else {
          this.$set(this.data, name, data[name]);
          this.$set(this.errors, name, null);
        }
      }

      this.loading = false;
      this.afterFetchData(data);
    },
    afterFetchData() {
      this.getId();
      if (this.autoCall) this.onCall(this.data);
    },
    onClick(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event, e);
      }
    },
    async saveOffer(d) {
      try {
        let data = Object.assign({}, d);
        delete data.createdon;
        delete data.updatedon;
        delete data.createdby_id;
        delete data.updatedby_id;

        let response = await this.$axios.post(this.url, data);
        let status = response.data.status == "ok";
        if (response.data.data) {
          this.$root.$emit("show-info", {
            text: "Данные записаны",
          });
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
      }
    },
    async getInfo() {
      if (this.isInfoProcess) return;
      this.isInfoProcess = true;
      let url;
      if (this.data.vendor == "avito") url = "https://avito.ru" + this.data.url;
      if (this.data.vendor == "cian") url = this.data.url;

      let info = await this.$axios.post("/offer/getFullInfo", { vendor_id: this.data.vendor_id, vendor: this.data.vendor, url });
      if (info.data.status == "ok" && info.data.data?.url) {
        let d = info.data.data;
        this.data.ads_info = d;
        if (d.description) {
          this.data.description = d.description;
        }
      }
      this.isInfoProcess = false;
      if (this.id && this.data.ads_info?.data != null) {
        let ads_info = Object.assign({}, this.data.ads_info);
        ads_info.data = null;

        let data = { id: this.id, ads_info, description: this.data.description };
        if (this.data.url == `/${this.data.vendor_id}` && ads_info.url) data.url = ads_info.url;
        await this.saveOffer(data);
      }
    },
    async getPhotoUnmark() {
      if (this.isInfoProcess) return;
      this.isInfoProcess = true;
      let url;
      if (this.data.vendor == "avito") url = (this.data.url.includes("avito.ru") ? "" : "https://avito.ru") + this.data.url;
      if (this.data.vendor == "cian") url = this.data.url;

      let info = await this.$axios.post("/offer/getPhotoUnmark", { url });
      if (info.data.status == "ok") {
        let d = info.data.data;
        this.data.images = d;
      }
      this.isInfoProcess = false;
      if (this.id) {
        let data = { id: this.id, images: this.data.images };
        await this.saveOffer(data);
      }
    },
    async onStartWork() {
      if (!this.data.total_area) {
        let t = this.data.title;
        console.log(t, t.match(/(\d+(?:[.,]\d+)?) м²/));
        //return;
      }
      let url = "/unrealty/object";
      let data = Object.assign({}, this.data);
      delete data.id;
      delete data.isNew;
      delete data.createdon;
      delete data.updatedon;
      data.user_id = this.$root.profile.id;
      data.offer_id = this.id;
      console.log(data);
      let response = await this.$axios.post(url, data);
      try {
        let status = response.data.status == "ok";
        if (response.data.data) {
          let id = response.data.data.id;

          this.$root.$emit("show-info", {
            text: "Объявление добавлено в избранное",
          });
          console.log("work", id);
          this.$emit("input");
          this.$emit("work", id);
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
      }
    },
  },
};
</script>
